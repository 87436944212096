import React, { useState } from 'react'
import { Link } from 'gatsby-plugin-modal-routing'
import Layout from '../layouts'

import './index.scss'

const toggleElement = (array, element) => {
  const elementIndex = array.indexOf(element)

  let newElements = []
  if (elementIndex == -1) {
    newElements = array.concat([element])
  } else {
    newElements = array.filter(item => item !== element)
  }

  return newElements
}

const decorateMealPlans = (mealPlans, allRecipes) => {
  mealPlans.forEach((mealPlan) => {
    mealPlan.recipes = allRecipes.filter((recipe) => {
      return mealPlan.recipeSlugs.indexOf(recipe.slug) != -1
    })
    console.log(mealPlan.recipes)
  })
}

const mealPlans = [
  {
    title: "Cook more, waste less",
    description: "These recipes share ingredients to help you get the most out of your groceries.",
    recipeSlugs: ["harissa-tofu-toast", "grilled-tempeh-sandwich", "chickpea-noodle-soup"]
  }
]

const AllRecipePage = ({ data }) => {
  const [selectedRecipes, setSelectedRecipes] = useState([])

  const toggleSelectedRecipe = (recipe) => {
    const newRecipes = toggleElement(selectedRecipes, recipe.slug)

    setSelectedRecipes(newRecipes)
  }

  const recipes = data.allContentfulRecipe.nodes

  let buttonClass = ""
  let buttonContainerClass = "sticky-bottom"
  let buttonCopy = `Shop these ${selectedRecipes.length} recipes`
  if (selectedRecipes.length == 0) {
    buttonClass = "disabled"
    buttonContainerClass = "Shop these recipes"
    buttonCopy = "Select a few recipes"
  } else if (selectedRecipes.length == 1) {
    buttonCopy = "Shop this recipe"
  }

  decorateMealPlans(mealPlans, recipes)

  return (
    <Layout>
      <div className="section bg-light">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2></h2>
            </div>
          </div>
          {mealPlans.map((plan, index) => {
            const link = `/lp/plant-based-recipes`

            return (
              <div className="row recipe-row py-4" key={`recipe-plan-${index}`}>
                <div className="col-12 col-md-5">
                  <h2 className="font-weight-bold display-3 mb-3">Quickly make your plant-based grocery list</h2>
                  <div className="col-12 col-md-7 d-md-none d-flex pt-1">
                    <div className="row align-self-center">
                      {plan.recipes.map((recipe, index) => {
                        return (
                          <div className="col-4 px-0" key={`meal-plan-recipe-sm-${index}-${recipe.slug}`}>
                            <img className="img-fluid" src={`${recipe.mainImage?.file?.url}?w=350`} alt={recipe.name} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <p className="small mb-4">Pick a few recipes and we’ll deliver everything you need.</p>
                  <Link to={link} className="btn-primary btn btn-lg">Get started</Link>
                </div>
                <div className="col-12 col-md-7 d-md-flex d-none">
                  <div className="row align-self-center">
                    {plan.recipes.map((recipe) => {
                      return (
                        <div className="col-4 px-0" key={`meal-plan-recipe-lg-${index}-${recipe.slug}`}>
                          <img className="img-fluid" src={`${recipe.mainImage?.file?.url}?w=350`} alt={recipe.name} />
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="container pt-5">
        <div className="row">
          <div className="col-12">
            <h2>Shop by recipe</h2>
            <div>
              <p>
                Select a few recipes and we’ll show you everything you need to get cooking.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pt-3 px-3 px-md-0">
          {recipes.map((recipe) => {
            const recipeClass = selectedRecipes.indexOf(recipe.slug) != -1 ? 'bg-dark border' : 'border border-white'

            return (
              <div className={`col-6 col-sm-4 pb-4 px-md-4 px-sm-2 px-1 pt-4`} key={recipe.slug}>
                <div className={`${recipeClass} p-1 cursor-pointer h-100 d-flex flex-column`}>
                  <div onClick={() => { toggleSelectedRecipe(recipe) }}>
                    <img className="img-fluid" src={`${recipe.rectangularImage?.file?.url}?w=550`} alt="A recipe" />
                    <h5 className="text-body pt-2 small">{recipe.name}</h5>
                    <div className="small smaller d-none d-sm-block" dangerouslySetInnerHTML={{ __html: recipe.shortDescription?.childMarkdownRemark?.html }}></div>
                  </div>
                  <Link
                    to={`/recipes/${recipe.slug}`}
                    className='smaller mt-auto'
                  >View recipe</Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className={`container-fluid bg-light py-4 ${buttonContainerClass}`}>
        <div className="row">
          <div className="col-12 text-center py-3">
            <Link
              to={`/shop-recipes?recipes=${selectedRecipes.join()}`}
              className={`btn btn-primary btn-lg ${buttonClass}`}
            >{buttonCopy}</Link>
          </div>
        </div>
      </div >
    </Layout >
  )
}

export const query = graphql`
  query ShopRecipes {
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        tags
        description {
          raw
        }
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        rectangularImage {
          file {
            url
          }
        }
        mainImage {
          file {
            url
          }
        }
        mainImage {
          file {
            url
          }
        }
      }
    }
  }
`

export default AllRecipePage
